body{background:url(/images/imgd281series/2/borderbody-top.png) repeat-x top}
h2, .h2{color:#242424;background:url(/images/imgd281series/2/border.png) repeat-x bottom}
h3, .h3{color:#000;border-bottom:1px solid #FFF}
h4, .h4{color:#000}
#wrapper{background:#FFF}
#headercontent a{color:#2d3b48}
.slogan{color:#000}
#nav-principal li a{color:#FFF}
#nav-principal li{background:#aaa}
#nav-principal li[class*="p-"]{background:#6E87B8}
#nav-principal li.p-accueil{background:#6E87B8}
#nav-principal li.p-blockpage{background:#6E87B8}
#nav-principal li[class*="b-"]{background:#4E6EAD}
#nav-principal li.b-blockboutique{background:#4E6EAD}
#nav-principal li[class*="a-"]{background:#324F89}
#nav-principal li.a-blockalbum{background:#324F89}
#nav-principal li.p-livreor{background:#2E3C59}
#nav-principal li.p-blog{background:#364667}
#nav-principal li.p-contact{background:#203660}
#nav-static-principal .sous_navigation_link_p{color:#000;background:url(/images/imgd281series/2/bullets.png) no-repeat left}
ul#slider{color:#000}
ul#slider a{color:#000}
.content_p a,.contents-tabs button{color:#40598b}
.pagination > .active > a,.pagination > .active > a:hover,.pagination > .active > a:focus,.pagination > .active > span,.pagination > .active > span:hover,.pagination > .active > span:focus{background-color:#40598b;border-color:#40598b}
.pagination > li > a,.pagination > li > span{color:#40598b}
#border{background:url(/images/imgd281series/2/border.png) repeat-x}
#simplePrevious,#simpleNext{background:url(/images/imgd281series/arrows-left.png) no-repeat}
#simpleNext{background:url(/images/imgd281series/arrows-right.png) no-repeat}
.produits-base{background:url(/images/imgd281series/produits-bg.png) no-repeat}
.produits-base:hover{background:url(/images/imgd281series/2/produits-bg-hover.png) no-repeat}
.produits{background:#FFF}
.produits p.titreprod{color:#242424}
.produits-base .produits a.addbasket,#contentpage .produits-base-bis .produits a.addbasket{background:url(/images/panier_add.png) no-repeat}
.galerie-base{background:url(/images/imgd281series/galerie-bg.png) no-repeat}
.galerie-base:hover{background:url(/images/imgd281series/2/galerie-bg-hover.png) no-repeat}
.galerie{background:#FFF}
.galerie a{color:#000}
.diaporama{color:#000}
.diaporama a{color:#000}
.diaporama a:hover{color:#40598b}
.message_lo{border:1px solid #ccc}
.message_lo p.web a{color:#242424}
a.addmsglo{background:#E1E1E1;border:2px solid #414141;color:#000}
a.addmsglo:hover{background:radial-gradient(#e1e1e1 35%, #717171 100%)}
#addmsg input{border:1px solid #ccc;color:#FFF}
#addmsg textarea{border:1px solid #ccc;color:#FFF}
#addmsg input[type=submit]{background:#FFF;border:2px solid #40598b;color:#000}
#addmsg input[type=submit]:hover{background:#40598b;color:#FFF;border:2px solid #FFF}
#addmsg a{color:#000}
#contactright{border:1px solid #ccc}
#addmsg input,#addmsg textarea,#form1 input,#form1 textarea{color:#242424;border:1px solid #ccc;background:#fff url(/images/form1/form_input.gif) repeat-x}
#form1 input.button{background:url(/images/imgd281series/bouton.png) top;color:#414141}
#wrapper #footer a{border-bottom:1px solid #000;color:#000}
#wrapper #footer a:hover{border-bottom:1px dotted #000}
#footer{background:url(/images/imgd281series/2/borderfooter.png) repeat-x bottom left;color:#000}
#pub{background:#000;color:#FFF}
h2 span.first-char, .h2 span.first-char,h4 span.first-char, .h4 span.first-char,#nav-static-principal .sous_navigation_button_p:hover > .sous_navigation_link_p, #nav-static-principal .sous_navigation_button_p.actif > .sous_navigation_link_p{color:#40598b}
.galerie img{border:1px solid #ccc}
.produits-base:hover img,.galerie-base:hover img,.message_lo:hover,#addmsg input:hover,#addmsg textarea:hover,#form1 input:hover,#form1 textarea:hover{border:1px solid #40598b}
#addmsg p,#form1 p{color:#000}
#addmsg label,#form1 label{background:url(/images/imgd281series/2/bullets.png) no-repeat left}

/** Flux Panier **/

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: #40598b;
  border-color: #40598b;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: #40598b;
  color: #40598b;

  a, span {
    color: #40598b;
  }
}